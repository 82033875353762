/**
 * Allows you to add data-method="METHOD to links to automatically inject a form
 * with the method on click
 *
 * Example: <a href="{{route('customers.destroy', $customer->id)}}"
 * data-method="delete" name="delete_item">Delete</a>
 *
 * Injects a form with that's fired on click of the link with a DELETE request.
 * Good because you don't have to dirty your HTML with delete forms everywhere.
 */
function addRestoreForms() {
    $('[data-method="restore"]').append(function () {
        if (! $(this).find('form').length > 0)
            return "\n" +
                "<form action='" + $(this).attr('href') + "' method='POST' name='restore_item' style='display:none'>\n" +
                "   <input type='hidden' name='_method' value='POST'>\n" +
                "   <input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                "</form>\n";
        else
            return "";
    })
        .removeAttr('href')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', '$(this).find("form").submit();');
}

function addDeleteForms() {
    $('[data-method="delete"]').append(function () {
        if (! $(this).find('form').length > 0)
            return "\n" +
                "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                "   <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                "   <input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                "</form>\n";
        else
            return "";
    })
        .removeAttr('href')
        .attr('style', 'cursor:pointer;')
        .attr('onclick', 'delConfirm($(this))');
}

// Confirm when Super Admin click 'Super Delete' Button
function delConfirm(data) {
    $.confirm({
        title: false,
        content: 'Are you really sure you wish to delete <strong style="font-size: 20px;">' + data.attr('data-name') + '</strong>??',
        type: 'red',
        columnClass: 'col-md-6 col-md-offset-3 col-sm-12',
        typeAnimated: true,
        offsetTop: 40,
        buttons: {
            yes: {
                btnClass: 'btn-danger',
                keys: ['y'],
                action: function () {
                    data.find("form").submit();
                }
            },
            no: {
                keys: ['N']
            },
        }
    });
}

function formatAMPM(thisDate) {
    var date = new Date(thisDate);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strDateTime;
}

function nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}

function setCookie(cname, cvalue) {
    const now = new Date();
    now.setTime(now.getTime() + 86400*1000);
    let expires = "expires=" + now.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

function FriendlyDate(text) {
  console.log(text);
    const parts = text.split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

/**
 * Place any jQuery/helper plugins in here.
 */
$(function(){
    /**
     * Place the CSRF token as a header on all pages for access in AJAX requests
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(".dropdown-submenu a.dropdown-toggle").click(function(e){
        $(this).closest( ".dropdown-submenu" ).toggleClass('open');
        console.log($(this).closest( ".dropdown" ).attr("class"));
        e.stopPropagation();
    });

    /**
     * Add the data-method="delete" forms to all delete links
     */
    addDeleteForms();
    addRestoreForms();
    /**
     * This is for delete buttons that are loaded via AJAX in datatables, they will not work right
     * without this block of code
     */
    $(document).ajaxComplete(function(){
        addDeleteForms();
        addRestoreForms();
    });

    /**
     * Generic confirm form delete using Sweet Alert
     */
    $('body').on('submit', 'form[name=delete_item]', function(e){
        e.preventDefault();
        var form = this;
        var link = $('a[data-method="delete"]');
            form.submit();
    });

    $('body').on('submit', 'form[name=restore_item]', function(e){
        e.preventDefault();
        var form = this;
        var link = $('a[data-method="restore"]');
        form.submit();
    });

    /**
     * Bind all bootstrap tooltips
     */
    $("[data-toggle=\"tooltip\"]").tooltip();

    /**
     * Bind all bootstrap popovers
     */
    $("[data-toggle=\"popover\"]").popover();

    /**
     * This closes the popover when its clicked away from
     */
    $('body').on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

});
